import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { useMusic } from "../../firebase/fbQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import MusicCard from "../common/MusicCard";
import MusicCardLoad from "../common/MusicCardLoad";
import { useState } from "react";
import {
  // useLocation,
  useNavigate,
} from "react-router-dom";
// import queryString from "query-string";

function Home() {
  const music = useMusic(db);
  // const { home } = useLocation();
  // const { search = "" } = queryString.parse(home);
  const [searchTerm, setSearchTerm] = useState("");
  const sameTitleMusic = music.filter((music) => {
    const matchesSearch = music.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const encodedSearchTerm = searchTerm.replace(/ /g, "+");
    navigate(`/?title=${encodedSearchTerm}`);
  };

  const resetInput = () => {
    setSearchTerm("");
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>Música - Daniel Ospid</title>
          <meta name="title" content={`Música - Daniel Ospid`} />
          <meta name="description" content={`Página de música de Daniel Ospid.`} />
          <link rel="canonical" href={`https://music.danielospid.com`} />

          {/* Open Graph */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`Música - Daniel Ospid`} />
          <meta property="og:description" content={`Página de música de Daniel Ospid.`} />
          <meta property="og:url" content={`https://music.danielospid.com/`} />
          <meta property="og:site_name" content={`Música - Daniel Ospid`} />
          <meta property="og:image" content={``} />
          <meta property="og:image:width" content="924" />
          <meta property="og:image:height" content="924" />
          <meta property="og:image:type" content="image/png" />

          {/* Twitter (X) */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:url" content={`https://music.danielospid.com/`} />
          <meta name="twitter:site" content="@danielospid" />
          <meta name="twitter:creator" content="@danielospid" />
          <meta name="twitter:title" content={`Música - Daniel Ospid`} />
          <meta name="twitter:description" content={`Página de música de Daniel Ospid.`} />
          <meta name="twitter:image" content={` `} />
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="banner-search">
            <div className="blur"></div>
            <h1>Busca Tus Lanzamiento Favorito de Daniel Ospid</h1>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Buscar por título..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button type="reset" title="Limpiar">
                <FontAwesomeIcon
                  icon={faClose}
                  size="xl"
                  onClick={() => resetInput()}
                />
              </button>
              <button type="submit" title="Buscar">
                <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
              </button>
            </form>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="grid">
            {sameTitleMusic.length === 0 ? (
              <>
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />

                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />

                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
              </>
            ) : (
              sameTitleMusic
                .sort((a, b) => b.date - a.date)
                .map((music, index) => <MusicCard key={index} {...music} />)
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default Home;
