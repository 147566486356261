import React, { useState, useEffect } from "react";

function Countdown(props) {
  const calculateTimeLeft = () => {
    const difference = +new Date(props.date.seconds * 1000) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      {timeLeft.days > 0 ||
      timeLeft.hours > 0 ||
      timeLeft.minutes > 0 ||
      timeLeft.seconds > 0 ? (
        <div className="music-release-date">
          <span className="available">Disponible</span>
          <span className="date-string">
            {new Date(props.date.seconds * 1000).toLocaleDateString(
              "es-ES",
              {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
              }
            )}
          </span>
          <div className="music-release-countdown">
            <div className="countdown-item">
              <span className="countdown-item-number">{timeLeft.days}</span>
              <span className="countdown-item-letter">Días</span>
            </div>
            <div className="countdown-item">
              <span className="countdown-item-number">{timeLeft.hours}</span>
              <span className="countdown-item-letter">Horas</span>
            </div>
            <div className="countdown-item">
              <span className="countdown-item-number">{timeLeft.minutes}</span>
              <span className="countdown-item-letter">Minutos</span>
            </div>
            <div className="countdown-item">
              <span className="countdown-item-number">{timeLeft.seconds}</span>
              <span className="countdown-item-letter">Segundos</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Countdown;
